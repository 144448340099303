import request from '@/utils/request'


// 这个接口是多用接口 可以搜索也可以获取
export function equipmentFaultManageSearch(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/search',
        method: 'post',
        data: data
    })
}

export function equipmentFaultManageRecentUpdate(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/recent_update',
        method: 'post',
        data: data
    })
}

export function equipmentFaultManageSubmitNewQuestion(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/submit/new_question',
        method: 'post',
        data: data
    })
}

export function equipmentFaultManageSubmitUpdate(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/submit/update',
        method: 'post',
        data: data
    })
}

export function equipmentFaultManageSubmitHistory(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/submit/history',
        method: 'post',
        data: data
    })
}

export function equipmentFaultManageGetinfo(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/getinfo',
        method: 'post',
        data: data
    })
}


// ---------------------------------------------------------------------------------

export function equipmentFaultManageQuestionTypeQuery(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/question_type/query',
        method: 'post',
        data: data
    })
}


export function equipmentFaultManageQuestionTypeCreate(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/question_type/create',
        method: 'post',
        data: data
    })
}


export function equipmentFaultManageQuestionTypeUpdate(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/question_type/update',
        method: 'post',
        data: data
    })
}


export function equipmentFaultManageQuestionTypeDelete(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/question_type/delete',
        method: 'post',
        data: data
    })
}

// 所有分类下的故障索引列表
export function equipmentFaultManageQuestionAllIndex(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/question_allindex',
        method: 'post',
        data: data
    })
}

//  指定分类下的所有故障 - 列表
export function equipmentFaultManageQuestionIndexList(data) {
    return request({
        url: '/platform_api/manage_api/equipment_fault_manage/question_allindex_list',
        method: 'post',
        data: data
    })
}