<template>
    <div :class="formConfig != '' ? 'filter-container':'filter-container-line'">
      <!-- 动态调用所有组件 -->
        <div class="filter-content">
            <el-form ref="form" :inline="true" :model="formModel" :rules="formRules" class="filtre-form" @submit.native.prevent>
                <el-form-item v-for="(item,index) in formConfig" :key= "item.prop + index" :label="item.label" :prop="item.prop">
                <component :is="item.component" v-model="formModel[item.prop]" :placeholder="item.placeholder" clearable>
                    <el-option v-for="(option,index) in item.options" :key="`shibo-${index}` " :label="option.label" :value="option.value"></el-option>
                </component>
                </el-form-item>
            </el-form>
        </div>
        <div class="filter-button">
            <el-form>
                <el-form-item>
                    <el-button v-for="button in buttons" :key="button.label" :type="button.type" @click="buttonClick(button)">{{ button.label }}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
  </template>
   
  <script scoped>
    // 目前正在使用的表格组件 最新版
    import { Input,Select,Option,Button } from 'element-ui';
   
    export default {
      data(){
        return {
        }
      },
      props:{
        formConfig: {
            type: Array,
        },
        formModel: {
            type: Object,
        },
        formRules: {
            type: Object,
            default: () => ({})
        },
        buttons: {
            type: Array,
            default: () => ([]),
        }
      },
      methods:{
        buttonClick(button) {
            this.$emit("button-click", button , this.formModel);
        }
      },
      components:{
        'el-input': Input,
        'el-select': Select,
        "el-option": Option,
        "el-button": Button
      }
    } 
  </script>
   

  
<style lang="scss" scoped>

  .filter-container-line{
        float: right;
        margin-top: 24px;
        margin-bottom: 10px;
    }
  
  .filter-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  </style>