<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'近期更新条目'" :line="true" />
            <CommonHeaders
                :formConfig="formConfig"
                :formModel="formModel"
                :buttons="buttons"
                @button-click="handleButtonClick"
            ></CommonHeaders>
        </div>

        <!-- 近期条目更新 -->
        <div
            class="content"
            style="
                margin-left: 60px;
                margin-top: 50px;
                margin-bottom: 20px;
                display: flow-root;
            "
            v-if="showpage == 1"
        >
            <el-col
                :span="18"
                v-for="data in tableData"
                :key="data.id"
                style="margin-bottom: 30px"
                @click.native="toInfo(data)"
            >
                <el-card
                    :body-style="{
                        padding: '30px 30px 0px 30px',
                        cursor: 'pointer'
                    }"
                    shadow="hover"
                >
                    <div style="margin-bottom: 20px">
                        <div style="font-size: 18px; font-weight: 600">
                            {{ data.abnormal_question }}
                        </div>
                    </div>

                    <div style="margin-bottom: 20px">
                        <div style="font-size: 14px; font-weight: 600">
                            更新者: {{ data.update_account }} 更新内容:
                            {{ data.update_illustrate }}
                        </div>
                    </div>

                    <el-form ref="form" :inline="true" label-position="left">
                        <el-form-item
                            style="margin-right: 30px"
                            label="更新类型:"
                        >
                            <span>{{ data.update_type }}</span>
                        </el-form-item>

                        <el-form-item
                            style="margin-right: 30px"
                            label="所属类目:"
                        >
                            <span>{{
                                data.question_type.type_name
                                    ? data.question_type.type_name
                                    : '无关联'
                            }}</span>
                        </el-form-item>

                        <el-form-item
                            style="margin-right: 30px"
                            label="软硬类型:"
                        >
                            <span>{{ data.types }}</span>
                        </el-form-item>

                        <el-form-item
                            style="margin-right: 30px"
                            label="提交人:"
                            v-if="data.author"
                        >
                            <span>{{ data.author }}</span>
                        </el-form-item>

                        <el-form-item
                            style="margin-right: 30px"
                            label="内容更新次数:"
                        >
                            <span>{{ data.update_count }}</span>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </div>
        <!-- 近期新增条目 -->
        <div
            class="content"
            style="
                margin-left: 60px;
                margin-top: 50px;
                margin-bottom: 20px;
                display: flow-root;
            "
            v-if="showpage == 0"
        >
            <el-col
                :span="18"
                v-for="(data, index) in tableData"
                :key="data.id + index"
                style="margin-bottom: 30px"
                @click.native="toInfo(data)"
            >
                <el-card
                    :body-style="{
                        padding: '30px 30px 0px 30px',
                        cursor: 'pointer'
                    }"
                    shadow="hover"
                >
                    <div style="margin-bottom: 20px">
                        <div style="font-size: 18px; font-weight: 600">
                            {{ data.abnormal_question }}
                        </div>
                    </div>

                    <el-form ref="form" :inline="true" label-position="left">
                        <el-form-item
                            style="margin-right: 30px"
                            label="所属类目:"
                        >
                            <span>{{
                                data.question_type.type_name
                                    ? data.question_type.type_name
                                    : '无关联'
                            }}</span>
                        </el-form-item>

                        <el-form-item
                            style="margin-right: 30px"
                            label="软硬类型:"
                        >
                            <span>{{ data.types }}</span>
                        </el-form-item>

                        <el-form-item
                            style="margin-right: 30px"
                            label="提交人:"
                            v-if="data.author"
                        >
                            <span>{{ data.author }}</span>
                        </el-form-item>

                        <el-form-item
                            style="margin-right: 30px"
                            label="内容更新次数:"
                        >
                            <span>{{ data.update_count }}</span>
                        </el-form-item>

                        <el-form-item
                            style="margin-right: 30px"
                            label="适用关键词:"
                            v-if="data.author"
                        >
                            <span>{{ data.question_suit }}</span>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </div>

        <!-- 分页器 -->
        <div class="layoutBorder" style="margin-left: 50px">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageCurrent.currentPage"
                :page-sizes="[5, 10, 20, 40]"
                :page-size="pageCurrent.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import {
    equipmentFaultManageRecentUpdate,
    equipmentFaultManageSearch
} from '@/api/manage/operation/equipment_fault_manage.js';
import CommonHeaders from '@/components/shibo_Common/CommonHeaders.vue';

export default {
    name: '',
    data() {
        return {
            form: {
                keyword: null
            },
            showpage: 0,
            tableData: [],
            pageCurrent: {
                currentPage: 1,
                total: 0,
                pageCount: 10,
                pageSize: 10
            },
            formConfig: [],
            formModel: {},
            buttons: [
                {
                    label: '查看近期新增条目',
                    type: '',
                    action: 'recent_create'
                },
                { label: '查看近期条目更新', type: '', action: 'recent_update' }
            ]
        };
    },
    created() {
        this.recent_create();
    },
    components: {
        CommonHeaders
    },
    methods: {
        recent_update() {
            equipmentFaultManageRecentUpdate(this.form).then((res) => {
                this.tableData = res.data.result;
                this.pageCurrent.currentPage = res.data.currentPage;
                this.pageCurrent.pageCount = res.data.pageCount;
                this.pageCurrent.total = res.data.total;
            });
        },
        recent_create() {
            equipmentFaultManageSearch(this.form).then((res) => {
                this.tableData = res.data.result;
                this.pageCurrent.currentPage = res.data.currentPage;
                this.pageCurrent.pageCount = res.data.pageCount;
                this.pageCurrent.total = res.data.total;
            });
        },
        handleButtonClick(button) {
            if (button.action === 'recent_update') {
                this.recent_update();
                this.showpage = 0;
            }
            if (button.action === 'recent_create') {
                this.recent_create();
                this.showpage = 1;
            }
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        toInfo(data) {
            console.log(data);
            this.$router.push({
                name: 'equipmentFaultManage_info',
                query: {
                    id: data.id
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped></style>
